.ant-table-wrapper {
  @include pxRem(padding, 0 10 !important);
}

.report-detail,
.report-edit {
  .reportCard .cardBody h2:before {
    display: none;
  }
  .report-status {
    @include pxRem(font-size, map-get($font-size, lg));
    font-weight: 500;
  }
  .addFeeField {
    position: relative;
    .ant-space-item:first-child {
      .ant-input-number-input-wrap {
        width: 90%;
      }
    }
    .ant-space-item:last-child,
    .prefix {
      @include pxRem(right, 6);
      position: absolute;
      top: 50%;
      transform: translateY(-50%);
      &.edit {
        @include pxRem(left, 126);
        width: fit-content;
      }
    }
    .addFee {
      @include pxRem(width, 150);
    }
  }
  .dateTime-picker {
    .ant-form-item-label > label::before {
      @include pxRem(margin-right, 4);
      display: inline-block;
      color: map-get($color, red-a);
      font-size: map-get($font-size, normal);
      font-family: SimSun, sans-serif;
      line-height: 1;
      content: '*';
    }
    .ant-form-item-control-input-content {
      display: flex;
      .ant-form-text {
        align-self: center;
      }
    }
  }
  .ant-card-body .echartsBox.radarBox {
    background-size: auto 310px !important;
  }
}

.print-top,
.print-top-sm,
.print-top-smallest {
  width: 100%;
}
@media print {
  .page-break {
    clear: both;
    break-after: page;
    page-break-after: always;
  }

  .cardBody.safari {
    @include pxRem(margin, 0 -20 !important);
  }

  .fillBlank.safari {
    @include pxRem(height, 966);
  }

  .first-collapse {
    @include pxRem(margin-top, 6);
  }

  .ant-card-body .echartsBox.radarBox {
    background-size: auto 310px !important;
  }

  .adPeriod {
    &.safari {
      .ant-collapse-content-box {
        padding-top: 0 !important;
      }
      .ant-collapse-header {
        @include pxRem(padding, 3 5 !important);
        @include pxRem(font-size, 18!important);
      }
      .ant-card-head-title {
        @include pxRem(font-size, 15 !important);
      }
      .manager-viewer {
        .title {
          @include pxRem(font-size, 14 !important);
        }
        .note {
          @include pxRem(font-size, 10);
        }
        .ant-card-body .viewer-value .value {
          @include pxRem(font-size, 22 !important);
        }
      }
    }
    .ant-card-head {
      @include pxRem(min-height, 30);
    }
    .ant-row {
      @include pxRem(row-gap, 8 !important);
    }
    .ant-collapse-content-box {
      @include pxRem(padding, 10 16);
    }
  }

  .viewer-section {
    .ant-col {
      position: relative;
      .vertical-line {
        @include pxRem(height, 80 !important);
      }
    }
    .ant-card-body {
      @include pxRem(padding, 8 !important);
      .title {
        @include pxRem(font-size, map-get($font-size, normal) !important);
      }
      .viewer-value .value {
        margin: 0 !important;
      }
    }
    .viewer-value {
      padding: 0;
    }
  }

  .ant-card-body {
    @include pxRem(padding, 10 !important);
    @include pxRem(font-size, 8 !important);
  }

  .print-title {
    @include pxRem(top, 6);
    @include pxRem(font-size, 22 !important);
    position: absolute;
    left: 50%;
    transform: translateX(-50%);
    display: block;
    font-weight: 700 !important;
    z-index: 10000;
    color: map-get($color, white) !important;
  }
  .ant-collapse > .ant-collapse-item {
    border-bottom: 0 !important;
  }
  .print-tail {
    left: 0;
    .top-page-1 {
      @include pxRem(bottom, 20);
    }
  }
  .print-top {
    @include pxRem(padding-top, 80);
    &-sm {
      @include pxRem(padding-top, 40 !important);
    }
    &-smallest {
      @include pxRem(padding-top, 40);
    }
    &.safari {
      @include pxRem(margin-top, 260);
    }
    .test {
      margin-top: 100px;
    }
  }
  .panel-page-2 {
    @include pxRem(margin-top, 340);
  }
}
