// == Breakpoints ===
$breakpoints: (
  small: 480px,
  medium: 769px,
  wide: 960px,
  large: 1024px,
  largest: 1280px,
  medium-largest: 1400px,
  super-largest: 1440px,
  header-medium: 900px
);

$color: (
  primary: #59c2ca,
  white: #ffffff,
  black: #000000,
  grey-a: #cacaca,
  grey-b: #d1d0d0,
  grey-c: #949494,
  red-a: #ff4d4f
);

$font-size: (
  sm: 12px,
  normal: 14px,
  medium: 16px,
  lg: 18px,
  large: 24px,
  super-large: 28px,
  xl: 32px
);
