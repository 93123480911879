// distance from top to footer
$value: 1607;
$valueSafari: 1535;

$sizeList: 0, 1, 2, 3, 4, 5;
$footerHeight: 28;

// distance from top to new page
@each $size in $sizeList {
  .top-page-#{$size} {
    @include pxRem(top, $value * $size + (($size - 1) * $footerHeight));
  }
  .top-page-safari-#{$size} {
    @include pxRem(top, $valueSafari * $size + (($size - 1) * $footerHeight));
  }
}
