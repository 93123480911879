.viewer-section {
  &.custom-col-60 {
    width: 60%;
  }
  &.custom-col-40 {
    width: 40%;
  }
  &.viewer-cost {
    .ant-card-bordered {
      .ant-col-12:first-child {
        .viewer-value {
          @include pxRem(margin-left, 8);
        }
      }
    }
  }
  .ant-col {
    position: relative;
    .vertical-line {
      @include pxRem(height, 110);
      position: absolute;
      top: 50%;
      right: 0;
      transform: translateY(-50%);
      z-index: 999;
      border-left: 1px solid map-get($color, grey-b);
    }
  }
  .custom-row {
    .ant-col {
      width: 20%;
    }
  }
  .ant-card,
  .ant-card-body {
    @include pxRem(border-radius, 4);
    height: 100%;
  }
  .ant-card-bordered {
    border: 1px solid map-get($color, grey-b);
    &:not(.ant-card-small) > .ant-card-body {
      padding: 0 !important;
    }
    .ant-col-2 {
      display: flex;
    }
  }
  .ant-card-body {
    text-align: center;
    .manager-viewer {
      @extend %flex-column;
      height: 100%;
      flex: 1;
      justify-content: space-between;
    }
    .sound-section {
      border-top: 1px solid map-get($color, grey-b);
      .sound-item {
        width: 50%;
        .sound-title {
          font-size: map-get($font-size, medium);
        }
        .sound-percent {
          font-size: 20px;
          position: relative;
          &::after {
            content: '%';
            position: absolute;
            right: -5px;
            top: 55%;
            transform: translateY(-50%);
            font-size: map-get($font-size, normal);
          }
        }
      }
      .sound-item:first-child {
        border-right: 1px solid map-get($color, grey-b);
      }
    }
    .title {
      @include pxRem(font-size, map-get($font-size, lg));
    }
    .note {
      @include pxRem(font-size, map-get($font-size, sm));
    }
    .viewer-value {
      position: relative;
      align-items: baseline;
      &.measuring-wrap {
        align-self: center;
      }
      .value {
        @include pxRem(margin, 10);
        @include pxRem(font-size, 26);
        &.measuring {
          @include pxRem(font-size, map-get($font-size, lg));
          @include pxRem(line-height, 50);
          font-weight: 500;
        }
      }
      .description,
      .unit {
        @include pxRem(font-size, map-get($font-size, normal));
      }
      .empty-desc {
        @include pxRem(min-width, 28);
        @include pxRem(height, 20);
      }
    }
  }
}
