.tooltip-icon {
  font-size: map-get($font-size, medium);
  color: map-get($color, grey-b);
  border-radius: 50%;

  .ant-tooltip-arrow-content,
  .ant-tooltip-inner {
    border: 1px solid map-get($color, primary);
    color: map-get($color, primary);
    font-size: map-get($font-size, normal);
  }
  .ant-tooltip-arrow-content {
    background: map-get($color, primary) !important;
  }
}
