.errorMessage {
  margin: 6px 0;
  height: 26px;
  color: red;
}

.contactModal {
  .ant-modal-close-x {
    font-size: 18px;
  }

  .ant-modal-body {
    font-size: 16px;
    padding-top: 48px;
    text-align: center;
    font-weight: bold;
  }
}