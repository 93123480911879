.echartsBox {
  width: 100%;
}
.radarBox {
  .radar-tooltip-wrapper {
    @include pxRem(width, 180);
    @include pxRem(margin-top, 5);
    @include pxRem(margin-bottom, 5);
    display: flex;
    justify-content: space-between;
  }
  .radar-tooltip-name {
    margin: 0;
    .tooltip-dot {
      @include pxRem(margin-right, 5);
      @include pxRem(border-radius, 10);
      @include pxRem(width, 9);
      @include pxRem(height, 9);
      display: inline-block;
    }
  }
  .tooltip-value {
    margin: 0;
    font-weight: 700;
  }
}

.areaBox {
  .area-tooltip-name,
  .tooltip-value {
    @include pxRem(margin-bottom, 4);
    font-weight: bold;
    .value {
      @include pxRem(margin, 0 10);
    }
  }
}
