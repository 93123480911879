@media print {
  .noPrint {
    display: none;
  }

  .fillBlank {
    height: 1040px;
  }
  .ant-collapse.ant-collapse-icon-position-right,
  .ant-collapse-item.ant-collapse-item-active {
    padding: 0 20px 20px;
  }

  .print_header {
    position: absolute;
    background-color: #59c2ca;
    color: white;
    font-size: 20px;
    font-weight: 500;
    padding-left: 10px;
    padding-bottom: 5px;
    text-align: left;
    width: 100%;
    top: 20px;
    left: 0;
    z-index: 0;
  }

  .print-tail {
    background-color: #59c2ca;
    margin: 0;
    padding-right: 25px;
    color: white;
    font-size: 16px;
    font-weight: 500;
    //line-height: 1.7715;
    width: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
    position: absolute;
    //left: 0;
    //bottom: 20px;

    .page-number {
      flex: 1;
      text-align: center;
      margin: 0 !important;
    }

    .company {
      flex: 1;
      text-align: right;
      margin: 0 !important;
    }
  }

  .print_header_second {
    .ant-collapse-header {
      margin-top: 35px;
    }
    &.adPeriod {
      .ant-collapse-header {
        margin-top: 10px;
      }
    }
  }
}

@media screen {
  .print_header {
    display: none;
  }

  .print-tail {
    display: none;
  }

  .no_show {
    display: none !important;
  }
}

@page {
  margin: 0;
}

.layoutArea {
  height: 100%;
  min-height: 100vh !important;
}

.siderArea {
  z-index: 999;

  &.ant-layout-sider-collapsed {
    .logoFold {
      font-size: 16px;
    }
  }

  .custom-menu {
    @include pxRem(padding-left, 24);
  }
  .logoFold {
    height: 64px;
    line-height: 64px;
    padding: 0 16px;
    margin: 0;
    font-size: 21px;
    font-weight: bold;
    color: #fff;
    cursor: pointer;

    sup {
      font-size: 20%;
      vertical-align: super;
    }
  }

  .trigger {
    position: absolute;
    top: 22px;
    color: rgba(255, 255, 255, 0.65);
    font-size: 20px;
    cursor: pointer;
    transition: color 0.3s;
  }

  .mainNav {
    height: calc(100vh - 249px);
    margin-bottom: 15px;
    overflow-y: auto;

    &.navUnfold {
      height: calc(100vh - 249px);
    }

    .ant-menu-inline.ant-menu-sub {
      box-shadow: none !important;
    }
  }

  .siderTool {
    @include pxRem(height, 70);
    @include pxRem(font-size, 18);
    @include pxRem(line-height, 70);
    position: absolute;
    bottom: 0;
    right: 50%;
    transform: translateX(50%);
    color: map-get($color, white);
    text-align: center;
    .anticon-menu-fold {
      display: block;
      line-height: 70px;
    }

    .anticon-menu-unfold {
      display: none;
      line-height: 70px;
    }

    &.toolUnfold {
      .anticon-menu-fold {
        display: none;
      }

      .anticon-menu-unfold {
        display: block;
      }
    }
  }
}

.headerArea {
  display: flex;
  justify-content: space-between;
  align-items: center;
  z-index: 888;
  width: 100%;
  min-width: 1480px;
  padding: 0 !important;
  color: #fff;
  text-align: right;
  box-shadow: 0 0 40px 0 rgba(82, 63, 105, 0.1);
}

.mainCont {
  height: calc(100vh - 64px);
  min-width: 1200px;
}

.footerArea {
  z-index: 888;
  width: 100%;
  padding-top: 20px;
  color: #000;
  text-align: center;
  transition: all 0.3s;

  &.footerFold {
    width: 100%;
  }
}

.rightLayout {
  // min-width: 1280px;
  min-width: auto;
}

.mainArea {
  height: calc(100vh - 65px);
  padding: 24px;
  overflow-x: auto;
  overflow-y: auto;
}

.subMainArea {
  padding: 24px 0 0;
  margin: 0;
}

.cardTabs {
  .ant-tabs-card {
    .ant-tabs-nav {
      .ant-tabs-tab {
        border-radius: 4px 4px 0 0 !important;
        border: 0;
        background: #f0f2f5;

        &.ant-tabs-tab-active {
          background: #fff;
        }
      }

      &::before {
        display: none;
      }
    }

    .ant-tabs-content {
      & > .ant-tabs-tabpane {
        background: #fff;
        border-radius: 0 4px 4px 4px;

        .subMainArea {
          padding: 0;
        }
      }
    }
  }
}
