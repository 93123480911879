@import url('https://fonts.googleapis.com/css2?family=Fira+Sans&display=swap');
@import url('https://fonts.googleapis.com/css2?family=Noto+Sans&display=swap');

.d-flex {
  display: flex;
}

.justify-between {
  display: flex;
  justify-content: space-between;
}
.flex-start-x {
  display: flex;
  justify-content: flex-start;
}
.flex-end-x {
  display: flex;
  justify-content: flex-end;
}

.flex-center-y {
  display: flex;
  align-items: center;
}
.flex-start-y {
  display: flex;
  align-items: flex-start;
}
.flex-end-y {
  display: flex;
  align-items: flex-end;
}

.flex-centered {
  @extend %flex-centered;
}

.flex-column-centered {
  @extend %flex-collumn-centered;
}

.flex-btw-x {
  @extend %flex-row-btw;
}

.pre-line {
  white-space: pre-line;
}

.txt-line-through {
  text-decoration: line-through;
}
.w-45-percent {
  width: 45%;
}
.w-60-percent {
  width: 60%;
}
.break-spaces {
  white-space: break-spaces;
}

.p-relative {
  position: relative;
}

.opacity-0 {
  opacity: 0;
}

.opacity-1 {
  opacity: 1;
}

.hidden {
  visibility: hidden;
}

.visible {
  visibility: visible;
}

.d-none {
  display: none;
}

.disable-link {
  pointer-events: none;
}

.overflow-visible {
  overflow: visible !important;
}

.break-spaces {
  white-space: break-spaces;
}
.overflow-break {
  overflow-wrap: break-word;
}

.break-wrd {
  word-break: break-word;
}
