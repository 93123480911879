.media-form {
  .time-duration-section {
    .ant-space-item:nth-child(2) {
      margin-top: 10px;
    }
    .media-duration {
      display: block;
      width: 140px;
    }
  }
  .time-box-group {
    .ant-space-item {
      width: 100px;
      &:nth-child(2) {
        width: max-content;
      }
    }
  }
  .media-percent {
    min-width: 120px;
    position: relative;
    &::after {
      position: absolute;
      content: '%';
      right: 10px;
      top: 12%;
    }
  }

  .time-box {
    width: 100%;
    justify-content: space-between;
  }
  .btn-add {
    width: 100%;
    justify-content: flex-end;
  }
}
