.dashboardCard {
  min-width: fit-content;
  &.reportCard {
    min-width: fit-content;
  }

  .ant-card-head-wrapper {
    .ant-tooltip {
      max-width: max-content;
    }
  }
}
