.csv-page {
  .ant-picker {
    width: 100%;
  }
}

.csv-form {
  .cardBody {
    min-height: 585px;
    .export-type-title {
      font-size: 18px;
      font-weight: 600;
    }
  }
}

.anticon-download {
  font-size: map-get($font-size, lg);
}
