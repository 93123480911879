.topMenu {
  display: flex;
  padding-right: 14px;
  overflow: hidden;
  color: #fff;
  background: #59c2ca;

  .topRight {
    width: 100%;
    height: 64px;
    display: flex;
    align-items: center;
    justify-content: flex-end;
    font-size: 0;
    list-style: none;
    margin: 0;
    padding: 0 12px 0 0;

    li {
      height: 64px;
      line-height: 64px;
      font-size: 14px;
      border-radius: 25px;
      text-align: center;
      margin-left: 16px;

      &.statistics {
        width: calc(100% - 160px);
        text-align: right;
      }

      .dropdownBox {
        display: inline-block;
        min-width: 50px;
        height: 64px;
        margin: 0;
        cursor: pointer;

        .userName {
          margin-right: 5px;
        }
      }

      &:last-child .ant-avatar {
        margin-bottom: 3px;
      }

      .anticon-bell {
        font-size: 20px;
        vertical-align: middle;
        color: #fff;
      }
    }
  }
}

.infoPopup {
  width: 460px;
}
