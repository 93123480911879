.loginBox {
  position: relative;
  min-width: 1200px;
  min-height: 758px;
  height: 100vh;
  background-color: #f0f2f5;
  background-image: url('https://gw.alipayobjects.com/zos/rmsportal/TVYTbAXWheQpRcWDaDMu.svg');
  background-repeat: no-repeat;
  background-size: 100%;
  overflow: auto;

  .imgGoogleBg {
    width: 1200px;
    height: 570px;
    margin: 0 auto !important;
    padding: 80px 0 0 650px;
    background-size: auto 570px;
    box-sizing: border-box;
  }

  .loginMain {
    display: flex;
    justify-content: center;
    align-items: center;
    min-height: 322px;
    height: 100%;
  }

  .imgBg {
    z-index: 0;
    width: 1200px;
    min-height: 322px;
    margin: 0 auto !important;
    box-sizing: border-box;

    .msgBox {
      width: 520px;
      min-height: 450px;
      padding-left: 35px;
      padding-right: 35px;
      border-radius: 27px;
      box-sizing: border-box;
      margin: 0 auto !important;
      background: #fff;
      padding-bottom: 5px;

      h1 {
        color: #05bac6;
        font-size: 36px;
        padding-top: 48px;
        margin-bottom: 46px;
        text-align: center;
      }

      .ant-form-item {
        margin-bottom: 10px;
      }

      .ant-input-prefix {
        font-size: 24px;
        color: #05bac6;
      }

      input {
        height: 80px;
        padding-left: 40px;

        &:-webkit-autofill {
          -webkit-box-shadow: 0 0 0 1000px #fffefe inset;
        }
      }

      .ant-input-affix-wrapper {
        padding: 0 0 0 11px;
        border: 0px;
        border-radius: 0;
        border-bottom: 1px solid #EEEEEE;
      }

      .remember {
        font-size: 14px;

        .ant-checkbox {
          input {
            height: auto;
            padding: 0;
          }
        }

        .forgetPsw {
          margin: 0;
          text-align: right;

          .forgetPswBtn {
            padding: 0 !important;
          }
        }
      }

      .loginBtnBox {
        text-align: center;
      }

      .loginBtn {
        font-size: 24px;
        width: 330px;
        height: 60px;
        color: #fff;
        margin: 27px auto;
        border: 0;
        border-radius: 38px;
      }
    }

    .qrCodeBox {
      width: 100%;
      max-width: 500px;
      background-color: #fff;
      padding: 3% 4.5% 4%;
      margin: 0 auto;
      border-radius: 5px;
      color: #333;

      .fontStyle {
        font-size: 14px;
      }

      h3 {
        text-align: center;
      }

      input {
        width: calc(100% - 132px);
        margin-right: 10px;
      }

      .codeFontBox {
        margin-bottom: 10px;
        color: #666;
        font-size: 12px;
        text-align: center;

        .codeFont {
          height: 30px;
          line-height: 30px;
          align-content: space-evenly;
        }

        Icon {
          font-size: 16px;
        }
      }
    }

    .login-form-button {
      display: block !important;
      width: 100%;
      height: 40px;
      font-size: 16px;
      margin-top: 24px;
    }

    .ant-form-item {
      padding: 0 !important;
    }

    .example-enter {
      opacity: 0.01;
    }

    .example-enter.example-enter-active {
      opacity: 1;
      animation: fadeInDown .5s ease-in;
    }

    .example-leave {
      opacity: 0.6;
    }

    .example-leave.example-leave-active {
      opacity: 0.01;
      animation: fadeOutDown .2s ease-out;
    }

    .example-appear {
      opacity: 0.01;
    }

    .example-appear.example-appear-active {
      opacity: 1;
      transition: opacity .5s ease-in;
    }

    @-webkit-keyframes fadeInDown {
      0% {
        opacity: 0;
        -webkit-transform: translate="true" 3 d(0, -100%, 0);
        transform: translate="true" 3 d(0, -100%, 0)
      }
      to {
        opacity: 1;
        -webkit-transform: none;
        transform: none
      }
    }
    @-webkit-keyframes fadeOutDown {
      0% {
        opacity: 1
      }
      to {
        opacity: 0;
        -webkit-transform: translate="true" 3 d(0, 100%, 0);
        transform: translate="true" 3 d(0, 100%, 0)
      }
    }
  }

  canvas {
    margin: 0 auto;
    display: block;
  }
}
